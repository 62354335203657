import cssStyles from './get_started_overlay.module.scss'
import { Dialog, DialogContent, IconButton, Grid, DialogTitle, useMediaQuery, Divider } from "@mui/material"
import { Close } from "@mui/icons-material"
import EachHeroTile from "./heroTile";
import { currentViewModeConstants } from './index';
import { Fragment, useMemo } from 'react';
import image1 from '../../assets/images/image 1016.png'
import image2 from '../../assets/images/image 1017.png'
import image3 from '../../assets/images/image 1018.png'
import tileImage from '../../assets/images/asrid-osamu-modern_250@2x.png'
import personalAssistantImage from '../../assets/images/personal assistance 1.png'
function GetStartedOverlay(props: any) {
  const { open, onClose, currentView ,handleSearchZipCodeLayerOpen} = props
  const items = [
    {
      id: 1,
      title: "Have a room in mind?",
      description:
        "Start with a room and design your perfect space",
      buttonText: "Select a room",
      pageLink: '/roomselection',
      image: tileImage.src,
      trackingObj: { event: "discover_room_get_started"}
    },
    {
      id: 2,
      title: "Start with a style",
      description:
        "Get personalized recommendations based on your style",
      buttonText: "Complete style profile",
      pageLink: '/stylequiz',
      image: image1.src,
      trackingObj: { event: "discover_style_get_started"}
    },
    {
      id: 3,
      title: "Start with ideas & inspiration",
      description: "Browse ideas and get inspired",
      buttonText: "Browse ideas",
      pageLink: '/inspirations',
      image: image2.src,
      trackingObj: { event: "browse_inspiration_get_started" }
    },
    {
      id: 4,
      title: "Prefer a human touch?",
      description: "Check out our talented designers",
      buttonText: "Find a designer",
      pageLink: handleSearchZipCodeLayerOpen,
      image: personalAssistantImage.src,
      trackingObj: {event: "find_a_designer_get_started" }
    },
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  const videoSource = !isMobile ? "https://d2ffb7z1ailwvo.cloudfront.net/assets/videos/nestingale-learn-more-video.mp4" : "https://d2ffb7z1ailwvo.cloudfront.net/assets/videos/nestingale-learn-more-video-mweb.mp4";
  // const shuffledItems = useMemo(() => {
  //         return shuffleArray([...items]);
  //    }, [open]);
  function renderGetStartView() {
    return <>  <DialogTitle
      className={cssStyles.getstartViewDialogTitle}
    >
      4 Easy Ways to Get Started
    </DialogTitle>
      <IconButton
        onClick={onClose}
        style={{
          position: "absolute",
          top: 10,
          right: 15,
          backgroundColor: "white",
          color: "black",
          borderRadius: "50%",
          padding: "6px",
          zIndex: 10,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        className={cssStyles.getstartViewDialogContent}>
        {items.map((eachItem: any, index: number) => (
          <Fragment key={eachItem.id}>
            <EachHeroTile key={eachItem.id} eachItem={eachItem} onClose={onClose} />
            {(isMobile && items.length - 1 !== index) && <Divider className={cssStyles.getStartViewDivider} />}
          </Fragment>
        ))}
        {/* Horizontal and Vertical Lines for Plus Symbol */}
        {!isMobile && (
          <>
            <div
            className={cssStyles.dividerX}
            />
            <div
            className={cssStyles.dividerY}
            />
          </>
        )}
      </DialogContent>

    </>
  }
  function renderVideoView() {
    return <>
      <DialogContent
        className={cssStyles.videoViewContent}
      >
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            top: 10,
            right: 15,
            backgroundColor: "white",
            color: "black",
            borderRadius: "50%",
            padding: "6px",
            zIndex: 10,
          }}
        >
          <Close />
        </IconButton>
        <div className={cssStyles.videoContainer}>
          <video loop autoPlay playsInline controls
            width="100%"
            src={videoSource}
            style={{
              height: '90%',
              width: '100%',
              objectFit:isMobile?'contain':'cover',
            }}
          />
        </div>
      </DialogContent>
    </>
  }
  function getCurrentView(currentView: string) {
    switch (currentView) {
      case currentViewModeConstants.getStartView:
        return renderGetStartView()
      case currentViewModeConstants.videoView:
        return renderVideoView()
      default:
        return null
    }
  }
  return <Dialog open={open} maxWidth="lg" fullWidth
    sx={{
      '& .MuiDialog-paper': {
        height: currentViewModeConstants.videoView === currentView ? '90vh' : null,
        // maxHeight: '90vh',
        borderRadius: '30px'
      },
    }}>
    {getCurrentView(currentView)}
  </Dialog>
}
export default GetStartedOverlay