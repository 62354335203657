
import cssStyles from './hero_tile.module.scss'
import { Grid, Typography, Button, useMediaQuery } from "@mui/material"
import { gtmClickTracking } from '@/utils';
function EachHeroTile(props: any) {
  const { id,title, description, image, buttonText, pageLink, trackingObj} = props.eachItem
  const{onClose}=props
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMd = useMediaQuery("(max-width:760px)");

  return <>
    <Grid item >
      <div
        className={cssStyles.heroTileBackGroundContainer}
      >
        <div className={cssStyles.heroTileImageAndTextBackGroundContainer}>
          <div>
            <img
              className={cssStyles.imagesize}
              src={image}
              alt={title}
            />
          </div>
          <div className={cssStyles.heroTiletextContainer}>
            <Typography
              className={cssStyles.heroTileTitle}
              variant="subtitle1"
              component="div"
            >
              {isMobile ? description : title}
            </Typography>
            {!isMobile && <Typography
              className={cssStyles.heroTileDescription}
              variant="body2"
              color="text.secondary"
            >
              {description}
            </Typography>}
          </div>
        </div>
        <div className={cssStyles.heroTileButtonContainer}>
          {id===4?<Button className={cssStyles.ctaButton}
            onClick={() =>{
              gtmClickTracking(trackingObj)
              onClose()
              pageLink(false)
            }}>{buttonText}</Button>
          :<Button
            className={cssStyles.ctaButton}
            onClick={() => gtmClickTracking(trackingObj)}
            href={pageLink}
          >
            {buttonText}
          </Button>}
        </div>
      </div>
    </Grid>
  </>
}
export default EachHeroTile
