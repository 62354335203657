'use client';

import React, { useEffect, useRef, useState } from "react";
import ClientComponent from "./client_component";
import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Project, actions as builderActions } from "@/store/builderSlice";
import BuilderSelct from "@/store/builderStoreSelector";
import { BUILDER_IN_PROGRESS } from "@/utils/builder/builderConstants";
import { saveProject as saveProjectThunk } from "@/store/builder/thunks/projectSaveThunk";
import { BUILDER_SUCCESS } from '@/utils/builder/builderConstants';
import cssStyles from './builder.module.scss';
import { gtmClickTracking, navigateWithHistory, getLoggedInEmailAddress, CART_TOKEN, GCART_TOKEN } from "@/utils";
import { ProjectProduct } from "@/store/projects";
import router, { useRouter } from "next/router";
import useShopifyCheckout from '../../hooks/useShopifyCheckout'
import { useShopify } from "@/store/shopifyReducer";
import { updateCartData } from "@/utils/useUserInfo";
import Layer from "../layer";
const viewConstants = {
    initial: 'initial',
    openPopup: 'openPopup',
    closePopup: 'closePopup'
}
const BuyNowComponent = () => {
    const [allproductVariantId, setAllProducts, userInfo] = useShopifyCheckout()
    const [filteredproductVariantId, setFilteredProducts] = useShopifyCheckout()

    const {
        checkoutState,
        createCheckout,
        addVariant,
        getClient,
        checkoutID
    } = useShopify();
    const router = useRouter()

    const dispatch = useDispatch();
    const project: Project = BuilderSelct.project();
    const saveStatus: string = BuilderSelct.ui().saveStatus;
    const urlParams: { [key: string]: string } = BuilderSelct.ui().urlParams;
    const isShowCaseFlow = urlParams.showcase === 'true';
    const buyNowRef = useRef(false);
    const [addToCartClicked, setAddToCartClicked] = useState(false);
    const [view, setView] = useState(viewConstants.initial)
    // const[productsInCart,setProductsInCart]=useState<any>([])
    let existingCartItems:any=[]
    if(checkoutState&&checkoutState?.lineItems){
        existingCartItems = checkoutState.lineItems?.map((lineItem: any) => lineItem.variant.sku);
    }
    const productsInCart = project?.products?.filter((item: any) => existingCartItems.includes(item.productId));
    const mergeCartItems = (items: any) => {
        const itemMap = new Map();

        items.forEach((item: any) => {
            if (itemMap.has(item.variantId)) {
                itemMap.get(item.variantId).quantity += item.quantity;
            } else {
                itemMap.set(item.variantId, { ...item });
            }
        });
        return Array.from(itemMap.values());
    };



    async function addProductToCheckout(checkoutId: any, productVariantId: any) {
        console.log("---------------addProductToCheckout--------------productVariantId:", productVariantId, 'checkoutId: ', checkoutId);
        const lineItemsToAdd = mergeCartItems(productVariantId);
        console.log(lineItemsToAdd, 'line items------------------')
        await addVariant(checkoutId, lineItemsToAdd);
    }
    async function handleAddToCart(productVariantId: any) {
        if (checkoutID) {
            if (typeof window !== 'undefined') {

                if (getLoggedInEmailAddress()) {
                    //@ts-ignore
                    window.setCookieWithoutSecure(CART_TOKEN, checkoutState.id, 10);
                } else {
                    //@ts-ignore
                    window.setCookieWithoutSecure(GCART_TOKEN, checkoutState.id, 10);
                }
            }
            //@ts-ignore
            await addProductToCheckout(checkoutID, productVariantId);
        }
    }
    useEffect(() => {
        if (!addToCartClicked) return;
        async function goToCart() {
            const response = await updateCartData(userInfo, checkoutState);
            console.log('----[[slug]] after updateCartData--------', response)
            //wait for checkout state to update after clicking add to cart
            //before taking the user to the cart page.
            setTimeout(() => {
                navigateWithHistory(router, `/cart`);
            }, 2000);
        }
        goToCart();
    }, [checkoutState])
    const saveAndNavigateToProjectPage = () => {
        setAllProducts(project.products)
        setFilteredProducts(project?.products?.filter((item: any) => !existingCartItems.includes(item.productId)))
        setAddToCartClicked(true);
        window?.nestDataLayer?.push({
            "event": "project_completed",
            "pageType": window.pageViewDataLayerObj.pageType,
            "pageName": window.pageViewDataLayerObj.pageName,
            "pagePath": window.pageViewDataLayerObj.pagePath,
            "projectId": project.projectId
        });

        buyNowRef.current = true;
        dispatch(builderActions.updateProjectStatus({ status: 'ready_to_buy' }));
        dispatch(builderActions.updateSaveStatus({ saveStatus: BUILDER_IN_PROGRESS }));
        dispatch(saveProjectThunk({}) as any);
    }

    if (buyNowRef.current && saveStatus === BUILDER_SUCCESS) {
        buyNowRef.current = false;
        // const origin =
        //     typeof window !== 'undefined' && window.location.origin
        //         ? window.location.origin
        //         : '';
        // let projectUrl = `${origin}/project?projectId=${project.projectId}`;
        // if (isShowCaseFlow) {
        //     projectUrl += '&showcase=true&auth=false'
        // } else {
        //     projectUrl += '&showcase=true';
        // }

        // window.location.href = projectUrl;
        if (productsInCart.length > 0) {
            setView(viewConstants.openPopup)
            setAddToCartClicked(false);

        } else {
            handleAddToCart(allproductVariantId)
        }


    }
    function handleClosePopup() {
        setView(viewConstants.closePopup)
        setAddToCartClicked(false)
    }
    function handleYes() {
        handleAddToCart(allproductVariantId)
        setView(viewConstants.closePopup)
        setAddToCartClicked(true)

    }
    function handleNo() {
        handleAddToCart(filteredproductVariantId)
        setView(viewConstants.closePopup)
        setAddToCartClicked(true)


    }
    function Popup() {
        return <>
            <div style={{ textAlign: 'center' }}>
                <h4 style={{margin:'5px',marginBottom:'10px',fontSize:'20px',lineHeight:'25px'}}>The product(s) below are already in your cart. Would you like to add another?</h4>
                <div className={cssStyles.cartItemsContainer}>
                    {
                        productsInCart?.map((eachProduct: any) => {
                            return <div key={eachProduct?.productName}>
                                <img src={eachProduct?.productImage} alt={eachProduct?.productName} width={80} />
                                <p>{eachProduct?.productName}</p>
                            </div>
                        })
                    }
                </div>
                <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '30px' }}>
                    <button onClick={handleYes} className={cssStyles.yes} style={{ padding: '0px', width: '100px', height: '45px' }}>yes</button>
                    <button onClick={handleNo} className={cssStyles.no} style={{ padding: '0px', width: '100px', height: '45px' }}>no</button>
                </div>
            </div>
        </>
    }
    function getPopUpView() {
        switch (view) {
            case viewConstants.openPopup:
                return <Layer
                    open={viewConstants.openPopup === view}
                    onClose={handleClosePopup}
                    dialogTitle=""
                    Element={Popup}
                    dialogContentClassName={"popup"}
                    maxWidth="sm"
                    dialogContentComponentProps={{
                        heading: '',
                    }}
                />
            default:
                return null
        }
    }
    return <Grid item>
        <Button
            variant="contained"
            onClick={saveAndNavigateToProjectPage}
            size="small"
            disabled={project.products.length < 1}
            className={cssStyles["next-button"]}
        >
            {addToCartClicked ? "Adding to Cart..." : "Add to Cart"}
        </Button>
        {getPopUpView()}
    </Grid >
}

export default ClientComponent(BuyNowComponent);