
import cssStyles from './new_hero_section.module.scss'
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import GetStartedOverlay from './getStartedOverlay';
import { gtmClickTracking } from '@/utils';
export const currentViewModeConstants = {
  getStartView: 'getStartView',
  videoView: 'videoView'
}
function HeroImage(props: any) {
  const { overlayOpen, setOverlayOpen, currentView, setCurrentView ,handleSearchZipCodeLayerOpen} = props
  // const isMobile = useMediaQuery("(max-width:600px)");

  function handleGetStarted() {
    gtmClickTracking({
      event:'get_started'
    })
    setOverlayOpen(true)
    setCurrentView(currentViewModeConstants.getStartView)

  }
  function onCloseOverlay() {
    setOverlayOpen(false)
  }
  function handleLearnMore() {
    gtmClickTracking({
      event:'learn_more'
    })
    setOverlayOpen(true)
    setCurrentView(currentViewModeConstants.videoView)

  }
  return <>
    <Box
      component='div'
      className={cssStyles.heroSectionBackgroundContainer}
    >
      {/*Top  Half-circle overlays */}
      <Box
        component='div'
        className={cssStyles.topCircle}
      >
        <div className={cssStyles.topCircleContentContainer}>
          <Typography
            variant="h4"
            component="h1"
            className={cssStyles.topCircleContent}
          >
            Reduce <i>stress</i>, <i>time</i>,<br /> and <i>cost</i> of <br /> decorating your <br />home
          </Typography>
        </div>


      </Box>
      {/*Top  Bottom-circle overlays */}

      <Box
        component='div'
        className={cssStyles.bottomCircle}
      >
        <Button
          className={cssStyles.getStartedButton}
          onClick={handleGetStarted}
          variant="contained"
        >
          Get Started
        </Button>
        <Button
          className={cssStyles.learnMoreButton}
          onClick={handleLearnMore}
        >
          Learn More
        </Button>

      </Box>
    </Box>
    <GetStartedOverlay open={overlayOpen} onClose={onCloseOverlay} currentView={currentView} handleSearchZipCodeLayerOpen={handleSearchZipCodeLayerOpen} />
  </>
}
export default HeroImage