import { createSlice, createAsyncThunk, current, createAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { produceWithPatches, original, produce, applyPatches } from 'immer';
import { Patch, WritableDraft } from 'immer/dist/internal';
import { BUILDER_FAILED, BUILDER_IN_PROGRESS, BUILDER_NOTIFICATION_ERROR, BUILDER_NOTIFICATION_SUCCESS, BUILDER_SUCCESS, DimensionalViewType, ProductPlacementType, RoomViewType, TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE } from '@/utils/builder/builderConstants';
import { fetchRoomLabels, fetchRoomModels, saveRoomLayout } from './builder/thunks/roomModelsThunk';
import { saveProject } from './builder/thunks/projectSaveThunk';
import { Box3 } from 'three';
import { loadDesign, loadDesignStyles } from './builder/thunks/builderDesignsLoader';
import { loadBuilderProductsByType } from './builder/thunks/builderProductsLoader';
import { findEndpoints, getCenterOfTiltedLine, intersectOfLines } from '@/utils/builder/twoDRoomLayoutUtils';

export interface BuilderProduct {
    productId: string;
    productName: string;
    productImage: string;
    productDesignImage: string;
    productDescription: string;
    productTypeName: string;
    isDefault: string;
    style: string;
    styleId: string;
    model3dUrl: string;
    price: number;
    comparePrice: number;
    productDimentions: {
        depth: number,
        width: number,
        height: number,
        weight: number
    },
    isBestSeller: boolean,
    similarProducts?: BuilderProduct[],
    relatedProducts?: BuilderProduct[],
    isFromRoomScan?: boolean;
    object3D?: any;
    availability?: string;
}

export interface BuilderProductType {
    productTypeId: string;
    name: string;
    displayName: string;
    imageUrl: string;
    productSubTypes: BuilderProductSubType[];
}

export interface BuilderProductSubType {
    productSubTypeId: string;
    productSubTypeName: string;
    productSubTypeDisplayName: string;
    productSubTypeImage: string;
}

export interface BuilderDesign {
    designId: string;
    designName: string;
    designImage: string;
    style: string;
    styleId: string;
    products: BuilderProduct[];
}

export interface DesignStyle {
    styleId: number;
    style: string;
    styleDisplayName: string;
    styleImage: string;
}

export interface DesignMap {
    [key: string]: {
        [key: string]: {
            [key: string]: BuilderDesign[]
        }
    }
}

export interface TwoDVertex {
    x: number;
    y: number;
}

export interface TwoDMesh {
    id: string;
    type: string;
    subType?: string;
    vertices: TwoDVertex[];
    position: TwoDVertex;
    rotation: number;
    width: number;
    color?: string;
    children: TwoDMesh[];
}

export interface TwoDRoomLayout {
    vertices: TwoDVertex[];
    centerPoint: TwoDVertex;
    boundingRect: { left: number; top: number; height: number; width: number };
    walls: TwoDMesh[];
    activeWallId: string;
    activeWindowOrDoorId: string;
    saveStatus: string;
    roomLabel: string;
    roomType: string;
    floorTextureId: string;
    captureThumbnailStatus: string;
    thumbnailImage: string;
}

export interface TwoDUi {
    vertices: TwoDVertex[];
    centerPoint: TwoDVertex;
    boundingRect: { left: number; top: number; height: number; width: number };
    walls: TwoDMesh[];
    showWallCtxMenu: boolean;
    deletedWindowOrDoorId: string;
    showSelectWallTooltip: boolean;
    showLayoutInfoTooltip: boolean;
}

export interface Texture {
    id: string;
    textureUrl: string;
    surfaceType: string;
    type: string;
    name: string;
    hexCode?: string;
    category?: string;
}
export interface PlacementCoords {
    x: number;
    y: number;
    z: number;
    w?: number;
}

export interface ProductPlacement {
    id: string;
    productId: string;
    type: string;
    position: PlacementCoords;
    rotation: PlacementCoords;
    scale: PlacementCoords;
    isFromRoomScan?: boolean;
}

export interface Surface {
    id: string;
    products: ProductPlacement[];
}

export interface SurfaceGroup {
    surfaceType: string;
    surfaces: Surface[];
}

export interface Project {
    projectId?: string;
    projectName: string;
    products: BuilderProduct[];
    surfaceGroups: SurfaceGroup[];
    roomModel: {
        roomId: string;
        model3DUrl: string;
        thumbnail: string;
    };
    origin: string;
    metaData: { [key: string]: string };
    status: string;
}

export interface ProductContent {
    products: BuilderProduct[];
    productTypes: BuilderProductType[];
}

export interface ActiveSelection {
    wallId: string;
    productPlacementId: string;
    roomId: string;
    roomBoundingBox: Box3 | null;
    screenCoords: {
        x: number;
        y: number;
    },
    showCtxMenu: boolean;
}

export interface DesignContent {
    textures: Texture[];
    designs: DesignMap;
    designStyles: DesignStyle[];
}

export interface ActionPatches {
    undoPatches: Patch[];
    redoPatches: Patch[];
}

export interface DragSession {
    isActive: boolean;
    productId: string;
}

export interface UIState {
    viewMode: string;
    roomView: string;
    dimensionalView: string;
    urlParams: { [key: string]: string };
    lightIntensity: number;
    lightColor: string;
    dragSession: DragSession;
    saveStatus: string;
    designStyleLoadStatus: string;
    isProjectDirty: boolean;
    enableDimensions: boolean;
    layoutBuilderMode: string;
    isCustomRoomFlow: boolean;
    styleName: string;
    designId: string;
    notificationMessage: { [key: string]: string | number } | undefined;
    currentTab: string;
}
export interface BuilderRoomAsset {
    _id: string;
    type: string;
    twoDimentionalUrl: string;
    threeDimentionalUrl: string;
    width: number;
    height: number;
    depth: number;
}

export interface BuilderRoomAssets {
    doorTypes: BuilderRoomAsset[];
    windowTypes: BuilderRoomAsset[];
}

export interface BuilderRoomType {
    roomTypeId: string;
    roomType: string;
    description: string;
    roomTypeDisplayName: string;
    status: string;
}


export interface RoomModel {
    roomId: string;
    roomName: string;
    roomType: string;
    roomLabel: string;
    usdzUrl: string;
    glbUrl: string;
    thumbnail: string;
    created_at: string;
    modified_at: string;
    origin: string;
    roomLayoutType: string;
    isRoomQuizComplete: boolean;
    data: { [key: string]: { [key: string]: string }[] },
    thumbnailSignedUrl: string;
    room2dFloorplanDimensionsImageSignedUrl: string;
    room2dFloorplanImageSignedUrl: string;
    cloned?: number;
}

export interface BuilderState {
    project: Project;
    productContent: ProductContent;
    designContent: DesignContent;
    undoStackPointer: number;
    undoStack: ActionPatches[];
    ui: UIState;
    activeSelection: ActiveSelection;
    roomModels: {
        models: RoomModel[],
        status: string;
    };
    roomAssets: BuilderRoomAssets;
    roomTypes: BuilderRoomType[];
    resourceLoadStatus: { [key: string]: string };
    floorPolygon: { x: number, y: number }[];
    twoDRoomLayout: TwoDRoomLayout;
    twoDUi: TwoDUi;
}
export const getInitialState = (data: any) => {
    return {
        project: {
            products: [],
            projectName: '',
            surfaceGroups: [],
            roomModel: {
                roomId: '',
                model3DUrl: '',
                thumbnail: ''
            },
            origin: 'BUILDER',
            metaData: {},
            status: ''
        } as Project,
        productContent: {
            products: [],
            productTypes: []
        } as ProductContent,
        designContent: {
            textures: [],
            designs: {},
            designStyles: [],
        } as DesignContent,
        undoStackPointer: -1,
        undoStack: [],
        ui: {
            viewMode: '',
            roomView: RoomViewType.default,
            dimensionalView: DimensionalViewType.THREE_D,
            urlParams: {},
            lightIntensity: 3,
            lightColor: '#ffffff',
            dragSession: {
                isActive: false,
                productId: '',
            },
            saveStatus: '',
            designStyleLoadStatus: '',
            isProjectDirty: false,
            enableDimensions: false,
            layoutBuilderMode: DimensionalViewType.TWO_D,
            isCustomRoomFlow: false,
            styleName: '',
            designId: '',
            notificationMessage: {},
            redirectPageUrl: '',
            currentTab: ''
        },
        activeSelection: {
            wallId: '',
            productPlacementId: '',
            roomId: '',
            roomBoundingBox: null,
            screenCoords: {
                x: 0,
                y: 0,
            },
            showCtxMenu: false
        },
        roomModels: {
            models: [] as RoomModel[],
            status: ''
        },
        roomAssets: {
            doorTypes: [],
            windowTypes: []
        },
        roomTypes: [],
        resourceLoadStatus: {} as { [key: string]: string },
        floorPolygon: [],
        twoDRoomLayout: {
            vertices: [],
            centerPoint: { x: 0, y: 0 },
            boundingRect: { left: 0, top: 0, width: 0, height: 0 },
            walls: [],
            activeWallId: '',
            activeWindowOrDoorId: '',
            saveStatus: '',
            roomLabel: '',
            roomType: '',
            floorTextureId: '',
            captureThumbnailStatus: '',
            thumbnailImage: ''
        } as TwoDRoomLayout,
        twoDUi: {
            vertices: [],
            centerPoint: { x: 0, y: 0 },
            boundingRect: { left: 0, top: 0, width: 0, height: 0 },
            walls: [],
            showWallCtxMenu: false,
            deletedWindowOrDoorId: '',
            showSelectWallTooltip: false,
            showLayoutInfoTooltip: false
        } as TwoDUi
    };
}

const produceWithPatch = (
    state: WritableDraft<BuilderState>,
    action: { payload: any; type?: string; },
    mutations: (draft: any) => void,
) => {
    if (!action.payload.undoable) {
        mutations(state);
        return;
    }

    const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
            mutations(draft);
        }
    );

    return produce(nextState, (draft) => {
        draft!.undoStackPointer++;
        draft!.undoStack.length = draft!.undoStackPointer;
        draft!.undoStack[draft!.undoStackPointer] = {
            redoPatches: patches,
            undoPatches: inversePatches
        };
        draft!.ui.isProjectDirty = true;
        console.log('adding to undo stack :: ', draft!.undoStackPointer);
    });
};

const getPositionForNewProduct = (roomBoundingBox: Box3 | null, type: string) => {
    if (type === ProductPlacementType.Texture || type === ProductPlacementType.Color) {
        return {
            x: 0, y: 0, z: 0
        }
    }
    if (!roomBoundingBox) throw Error('Room dimensions are not available');
    const roomWidth = roomBoundingBox.max.x - roomBoundingBox.min.x;
    const roomHeight = roomBoundingBox.max.z - roomBoundingBox.min.z;
    return {
        x: roomBoundingBox.min.x + roomWidth / 2,
        y: -1,
        z: roomBoundingBox.min.z + roomHeight / 2,
    };
}

const addProductsData = (state: BuilderState, products: BuilderProduct[]) => {
    state.productContent.products = state.productContent.products.concat(products);
}

const updateDesignData = (state: BuilderState, designData: any) => {
    let products: BuilderProduct[] = [];
    for (let roomName of Object.keys(designData)) {
        const inputRoomDesigns = designData[roomName];
        let curRoomDesigns = state.designContent.designs[roomName];
        if (!curRoomDesigns) {
            curRoomDesigns = {};
            state.designContent.designs[roomName] = curRoomDesigns;
        }
        for (let vendorName of Object.keys(inputRoomDesigns)) {
            const inputVendorDesigns = inputRoomDesigns[vendorName];
            let curVendorDesigns = curRoomDesigns[vendorName];
            if (!curVendorDesigns) {
                curVendorDesigns = {};
                curRoomDesigns[vendorName] = curVendorDesigns;
            }
            for (let styleName of Object.keys(inputVendorDesigns)) {
                let curStyleDesigns = curVendorDesigns[styleName];
                const inputDesigns = inputVendorDesigns[styleName];
                if (!curStyleDesigns) {
                    curStyleDesigns = [];
                    curVendorDesigns[styleName] = curStyleDesigns;
                }
                curVendorDesigns[styleName] = curStyleDesigns.concat(inputDesigns);
                inputDesigns.forEach((design: BuilderDesign) => {
                    products = products.concat(design.products);
                });
            }
        }
    }
    addProductsData(state, products);
}

const getTwoDMeshRotation = (startVertex: TwoDVertex, endVertex: TwoDVertex) => {
    const m = (endVertex.y - startVertex.y) / (endVertex.x - startVertex.x);
    const angleInRadians = Math.atan(m);
    const angleInDegrees = (angleInRadians * 180) / Math.PI;
    return angleInDegrees;
}

const getTwoDMeshWidth = (startVertex: TwoDVertex, endVertex: TwoDVertex) => {
    return Math.sqrt(Math.pow(endVertex.x - startVertex.x, 2) + Math.pow(endVertex.y - startVertex.y, 2));
}
const updateTwoDWallsLayout = (state: BuilderState, vertices: TwoDVertex[]) => {
    const twoDMeshes: TwoDMesh[] = [];
    for (let i = 0; i < vertices.length; i++) {
        const endVertexIndex = i === vertices.length - 1 ? 0 : i + 1;
        const wallMesh = {
            id: `wall${i}`,
            type: 'wall',
            vertices: [vertices[i], vertices[endVertexIndex]],
            position: getCenterOfTiltedLine(vertices[i], vertices[endVertexIndex]),
            rotation: getTwoDMeshRotation(vertices[i], vertices[endVertexIndex]),
            width: getTwoDMeshWidth(vertices[i], vertices[endVertexIndex]),
            children: []
        }
        twoDMeshes.push(wallMesh);
    }
    if (state.twoDRoomLayout.walls.length === 0) {
        state.twoDRoomLayout.walls = twoDMeshes;
    } else if (state.twoDRoomLayout.walls.length === twoDMeshes.length) {
        twoDMeshes.forEach((wallMesh, index) => {
            const wall = state.twoDRoomLayout.walls[index];
            wall.vertices = wallMesh.vertices;
            wall.position = wallMesh.position;
            wall.rotation = wallMesh.rotation;
            wall.width = wallMesh.width;
            wall.children.forEach(child => {
                child.rotation = wallMesh.rotation;
                const wallEndPoints = findEndpoints(wall.position.x, wall.position.y, wall.rotation, wall.width);
                const childEndpoints = findEndpoints(child.position.x, child.position.y, (90 + wall.rotation) % 180, 1000);
                const intersectPoint = intersectOfLines(
                    wallEndPoints.endpoint1.x,
                    wallEndPoints.endpoint1.y,
                    wallEndPoints.endpoint2.x,
                    wallEndPoints.endpoint2.y,
                    childEndpoints.endpoint1.x,
                    childEndpoints.endpoint1.y,
                    childEndpoints.endpoint2.x,
                    childEndpoints.endpoint2.y
                );
                if (intersectPoint)
                    child.position = intersectPoint;
            });
        })
    }
}

const BuilderSlice = createSlice({
    name: "builder",
    initialState: getInitialState({}),
    reducers: {
        //@ts-ignore
        addProductToProject: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                if (action.payload.type === ProductPlacementType.Product) {
                    const product = state.productContent.products.find(product => product.productId === action.payload.productId);
                    if (!product) return;
                    draft.project.products.push(product);
                }
                const productPlacement = {
                    id: uuid(),
                    productId: action.payload.productId,
                    type: action.payload.type,
                    position: action.payload.position || getPositionForNewProduct(state.activeSelection.roomBoundingBox, action.payload.type),
                    rotation: {
                        x: 0,
                        y: 0,
                        z: 0,
                        w: 0
                    },
                    scale: {
                        x: 1,
                        y: 1,
                        z: 1
                    }
                } as ProductPlacement;
                const surfaceType = action.payload.surfaceType;
                const surfaceId = action.payload.surfaceId;
                let surfaceGroup: SurfaceGroup | undefined = draft.project.surfaceGroups.find((surfaceGroup: SurfaceGroup) => surfaceGroup.surfaceType === surfaceType);
                if (!surfaceGroup) {
                    surfaceGroup = {
                        surfaceType,
                        surfaces: []
                    }
                    draft.project.surfaceGroups.push(surfaceGroup);
                }

                let surface = surfaceGroup.surfaces.find(surface => surface.id === surfaceId);
                if (!surface) {
                    surface = {
                        id: surfaceId,
                        products: []
                    }
                    surfaceGroup.surfaces.push(surface);
                }
                if (action.payload.type === ProductPlacementType.Texture ||
                    action.payload.type === ProductPlacementType.Color) {
                    const currentTextureIndex = surface.products.findIndex(placement =>
                        placement.type === ProductPlacementType.Texture ||
                        placement.type === ProductPlacementType.Color
                    );
                    if (currentTextureIndex > -1)
                        surface.products.splice(currentTextureIndex, 1);
                }
                surface.products.push(productPlacement);
            });
        },

        addRoomScanProductsToProject: (state, action) => {
            const placments: any[] = action.payload.placements;
            placments.forEach(placement => {
                const productPlacement = {
                    id: uuid(),
                    productId: placement.productId,
                    type: action.payload.type,
                    position: placement.position,
                    rotation: placement.rotation,
                    scale: {
                        x: 1,
                        y: 1,
                        z: 1
                    },
                    isFromRoomScan: true
                } as ProductPlacement;
                const surfaceType = action.payload.surfaceType;
                const surfaceId = action.payload.surfaceId;
                let surfaceGroup: SurfaceGroup | undefined = state.project.surfaceGroups.find((surfaceGroup: SurfaceGroup) => surfaceGroup.surfaceType === surfaceType);
                if (!surfaceGroup) {
                    surfaceGroup = {
                        surfaceType,
                        surfaces: []
                    }
                    state.project.surfaceGroups.push(surfaceGroup);
                }

                let surface = surfaceGroup.surfaces.find(surface => surface.id === surfaceId);
                if (!surface) {
                    surface = {
                        id: surfaceId,
                        products: []
                    }
                    surfaceGroup.surfaces.push(surface);
                }
                surface.products.push(productPlacement);
            });

        },

        //@ts-ignore
        updateTextureForAllWalls: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                // const product = state.productContent.products.find(product => product.productId === action.payload.productId);
                // if (!product) return;
                // draft.project.products.push(product);
                const productPlacement = {
                    id: uuid(),
                    productId: action.payload.productId,
                    type: action.payload.type,
                    position: {
                        x: -0.75,
                        y: -1,
                        z: -0.25
                    },
                    rotation: {
                        x: 0,
                        y: 0,
                        z: 0,
                        w: 0
                    },
                    scale: {
                        x: 1,
                        y: 1,
                        z: 1
                    }
                } as ProductPlacement;
                const surfaceType = action.payload.surfaceType;
                let surfaceGroup: SurfaceGroup | undefined = draft.project.surfaceGroups.find((surfaceGroup: SurfaceGroup) => surfaceGroup.surfaceType === surfaceType);
                if (surfaceGroup) {
                    surfaceGroup.surfaces.forEach(surface => {
                        const currentTextureIndex = surface.products.findIndex(placement =>
                            placement.type === ProductPlacementType.Texture ||
                            placement.type === ProductPlacementType.Color
                        );
                        if (currentTextureIndex > -1)
                            surface.products.splice(currentTextureIndex, 1);
                        surface.products.push(productPlacement);
                    })
                }
            });
        },

        //@ts-ignore
        removeProductFromProject: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                let productId: string | undefined = undefined;
                const placementId = action.payload.productPlacementId;

                let placementIndex = -1;
                const surfaceType = action.payload.surfaceType;
                const surfaceId = action.payload.surfaceId;
                let surface: Surface | undefined;
                const surfaceGroup: SurfaceGroup | undefined = draft.project.surfaceGroups.find((surfaceGroup: SurfaceGroup) =>
                    surfaceGroup.surfaceType === surfaceType
                );
                if (surfaceGroup) {
                    surface = surfaceGroup.surfaces.find(surface => surface.id === surfaceId);
                    if (surface) {
                        placementIndex = surface.products.findIndex((placement) => placement.id === placementId);
                        if (placementIndex > -1) {
                            const productPlacement = surface.products[placementIndex];
                            productId = productPlacement.productId;
                            surface.products.splice(placementIndex, 1);
                            draft.activeSelection.productPlacementId = '';
                            draft.activeSelection.screenCoords = { x: 0, y: 0 };
                        }
                    }
                }

                if (productId) {
                    const productIdIndex = state.project.products.findIndex(product => product.productId === productId);
                    if (productIdIndex > -1)
                        draft.project.products.splice(productIdIndex, 1);
                }
            });
        },

        clearProductsFromProject: (state, action) => {
            state.project.products = [];
        },

        initProject: (state, action) => {
            const project: Project = action.payload.project;
            state.project = project;
        },

        //@ts-ignore
        updateProductInProject: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                const newProductPlacement = action.payload.productPlacement;
                let curProductPlacement;
                const surfaceType = action.payload.surfaceType;
                const surfaceId = action.payload.surfaceId;
                const surfaceGroup: SurfaceGroup | undefined = draft.project.surfaceGroups.find((surfaceGroup: SurfaceGroup) =>
                    surfaceGroup.surfaceType === surfaceType
                );
                if (surfaceGroup) {
                    const surface = surfaceGroup.surfaces.find(surface => surface.id === surfaceId);
                    if (surface) {
                        curProductPlacement = surface.products.find(productPlacement =>
                            productPlacement.id === newProductPlacement.id
                        );
                    }
                }

                if (curProductPlacement) {
                    if (newProductPlacement.position) {
                        curProductPlacement.position = newProductPlacement.position;
                    }
                    if (newProductPlacement.rotation) {
                        console.log('update project :: ', newProductPlacement.rotation);
                        curProductPlacement.rotation = newProductPlacement.rotation;
                    }
                    if (newProductPlacement.scale) {
                        curProductPlacement.scale = newProductPlacement.scale;
                    }
                }
            });

        },

        //@ts-ignore
        updateProjectName: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                draft.project.projectName = action.payload.projectName;
            });
        },
        //@ts-ignore
        updateProjectMetdata: (state, action) => {
            const newMetadata = action.payload.metadata;
            if (newMetadata) {
                Object.keys(newMetadata).forEach(key => {
                    state.project.metaData[key] = newMetadata[key];
                })
            }
        },

        updateProjectStatus: (state, action) => {
            const newStatus = action.payload.status;
            if (newStatus) {
                state.project.status = newStatus;
            }
        },

        //@ts-ignore
        addWallTextureToProject: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                draft.project.wallTextures = [action.payload.wallTextureId];
            });
        },
        addFloorTextureToProject: (state, action) => {
        },
        setProductTypeContent: (state, action) => {
            state.productContent.productTypes = action.payload.productTypes;
        },
        addProductData: (state, action) => {
            addProductsData(state, action.payload.products);
        },
        setProductContent: (state, action) => {
            state.productContent.products = action.payload.products;
        },
        setDesignContent: (state, action) => {
            state.designContent.textures = state.designContent.textures.concat(action.payload.textures);
        },
        setViewMode: (state, action) => {
            state.ui.viewMode = action.payload.viewMode;
        },
        setDimensionsMode: (state, action) => {
            state.ui.enableDimensions = action.payload.enableDimensions;
        },
        updateDesignData: (state, action) => {
            if (action.payload.styleName)
                state.ui.styleName = action.payload.styleName;
            if (action.payload.designId)
                state.ui.designId = action.payload.designId;
        },
        updateDesignContent: (state, action) => {
            state.designContent.textures = action.payload.textures;
        },
        updateActiveSelection: (state, action) => {
            if (action.payload.wallId !== undefined)
                state.activeSelection.wallId = action.payload.wallId;
            if (action.payload.productPlacementId !== undefined)
                state.activeSelection.productPlacementId = action.payload.productPlacementId;
            if (action.payload.screenCoords !== undefined)
                state.activeSelection.screenCoords = action.payload.screenCoords;
            if (action.payload.showCtxMenu !== undefined)
                state.activeSelection.showCtxMenu = action.payload.showCtxMenu;
        },
        updateResourceLoadStatus: (state, action) => {
            if (action.payload.name !== undefined && action.payload.status !== undefined) {
                state.resourceLoadStatus[action.payload.name as string] = action.payload.status;
            }
        },
        updateFloorPolygon: (state, action) => {
            if (action.payload.polygon !== undefined) {
                state.floorPolygon = action.payload.polygon;
            }
        },
        updateRoomBoundingBox: (state, action) => {
            if (action.payload.roomBoundingBox !== undefined)
                state.activeSelection.roomBoundingBox = action.payload.roomBoundingBox;
        },
        updateRoomId: (state, action) => {
            if (action.payload.roomModel !== undefined) {
                state.activeSelection.roomId = action.payload.roomModel.roomId;
                state.project.roomModel = action.payload.roomModel;
            }
        },
        setUrlParams: (state, action) => {
            if (action.payload.urlParams !== undefined)
                state.ui.urlParams = action.payload.urlParams;
        },
        updateCustomRoomFlow: (state, action) => {
            state.ui.isCustomRoomFlow = action.payload.isCustomRoomFlow;
        },
        updateLightIntensity: (state, action) => {
            if (action.payload.lightIntensity !== undefined)
                state.ui.lightIntensity = action.payload.lightIntensity;
            if (action.payload.lightColor !== undefined)
                state.ui.lightColor = action.payload.lightColor;
        },
        updateDragSession: (state, action) => {
            if (action.payload.dragSession !== undefined)
                state.ui.dragSession = action.payload.dragSession;
        },
        updateSaveStatus: (state, action) => {
            if (action.payload.saveStatus !== undefined)
                state.ui.saveStatus = action.payload.saveStatus;
        },
        updateNotificationMessage: (state, action) => {
            state.ui.notificationMessage = action.payload;
        },
        updateRoomView: (state, action) => {
            if (action.payload.roomView !== undefined)
                state.ui.roomView = action.payload.roomView;
        },
        updateDimensionalView: (state, action) => {
            if (action.payload.dimensionalView !== undefined)
                state.ui.dimensionalView = action.payload.dimensionalView;
        },
        updatCurrentTab: (state, action) => {
            if (action.payload.currentTab !== undefined)
                state.ui.currentTab = action.payload.currentTab;
        },
        undo: (state, action) => {
            if (state.undoStackPointer > -1) {
                const { undoPatches } = state.undoStack[state.undoStackPointer];
                state.undoStackPointer--;
                state.ui.isProjectDirty = true;
                applyPatches(state, undoPatches);
            }
        },
        redo: (state, action) => {
            if (state.undoStackPointer < state.undoStack.length - 1) {
                state.undoStackPointer++;
                const { redoPatches } = state.undoStack[state.undoStackPointer];
                state.ui.isProjectDirty = true;
                applyPatches(state, redoPatches);
            }
        },
        addWallTextureColorContent: (state, action) => {
            state.designContent.textures.push(action.payload.texture);
        },
        addRoomAssets: (state, action) => {
            state.roomAssets = action.payload.roomAssets;
        },
        addRoomTypes: (state, action) => {
            state.roomTypes = action.payload.roomTypes;
        },
        addDesigns: (state, action) => {
            updateDesignData(state, action.payload.designData);
        },

        //TwoD layout specific actions 
        //@ts-ignore
        updateTwoDLayoutVertices: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                draft.twoDRoomLayout.vertices = action.payload.vertices;
                draft.twoDRoomLayout.centerPoint = action.payload.centerPoint;
                draft.twoDRoomLayout.boundingRect = action.payload.boundingRect;
                updateTwoDWallsLayout(draft, action.payload.vertices);
            });
        },
        //@ts-ignore
        addTwoDWallMeshChild: (state, action) => {
            const wallMesh: TwoDMesh = Object.assign({}, action.payload.wallMesh);
            return produceWithPatch(state, action, (draft) => {
                const currentWall = draft.twoDRoomLayout.walls.find((wall: TwoDMesh) => wall.id === action.payload.wallId);
                if (currentWall) {
                    if (wallMesh.position == null) {
                        wallMesh.position = getCenterOfTiltedLine(currentWall.vertices[0], currentWall.vertices[1]);
                    }
                    if (wallMesh.rotation == null) {
                        wallMesh.rotation = currentWall.rotation;
                    }
                    draft.twoDRoomLayout.activeWindowOrDoorId = wallMesh.id;
                    currentWall.children.push(wallMesh);
                }
            });
        },

        //@ts-ignore
        addTwoDWallMeshChildAtCenter: (state, action) => {
            const currentWall = state.twoDRoomLayout.walls.find((wall: TwoDMesh) => wall.id === action.payload.wallId);
            if (currentWall) {
                const childMesh = action.payload.wallMesh;
                childMesh.rotation = currentWall.rotation;
                childMesh.position = getCenterOfTiltedLine(currentWall.vertices[0], currentWall.vertices[1]);
                childMesh.position.x += 0.15;
                childMesh.position.y += 0.15;
                currentWall.children.push(action.payload.wallMesh);
            }
        },
        //@ts-ignore
        removeTwoDWallMeshChild: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                const currentWall = draft.twoDRoomLayout.walls.find((wall: TwoDMesh) => wall.id === action.payload.wallId);
                if (currentWall) {
                    const childIndex = currentWall.children.findIndex((child: TwoDMesh) => child.id === action.payload.childId);
                    if (childIndex > -1) {
                        currentWall.children.splice(childIndex, 1);
                        draft.twoDRoomLayout.activeWindowOrDoorId = '';
                        draft.twoDUi.deletedWindowOrDoorId = action.payload.childId;
                    }

                }
            });
        },
        setRoomLayoutFromModel: (state, action) => {
            const roomModel = action.payload.roomModel;
            if (roomModel && roomModel.roomLayoutType === '2D') {
                state.ui.isCustomRoomFlow = true;
                //@ts-ignore
                state.twoDRoomLayout.vertices = roomModel.data.vertices;
                //@ts-ignore
                state.twoDRoomLayout.walls = roomModel.data.walls;
            }
        },
        updateActiveWallId: (state, action) => {
            state.twoDRoomLayout.activeWallId = action.payload.activeWallId;
        },
        //@ts-ignore
        updateWallColor: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                let currentWall;
                if (action.payload.wallId) {
                    currentWall = draft.twoDRoomLayout.walls.find((wall: TwoDMesh) => wall.id === action.payload.wallId);
                }
                if (currentWall) {
                    currentWall.color = action.payload.color;
                } else {
                    draft.twoDRoomLayout.walls.forEach((wall: TwoDMesh) => {
                        wall.color = action.payload.color;
                    });
                }
            });
        },
        //@ts-ignore
        updateFloorTexture: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                draft.twoDRoomLayout.floorTextureId = action.payload.floorTextureId;
            });
        },
        updateActiveWindowOrDoorId: (state, action) => {
            state.twoDRoomLayout.activeWindowOrDoorId = action.payload.activeWindowOrDoorId;
        },
        //@ts-ignore
        updateTwoDWallMeshChild: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                const currentWall = draft.twoDRoomLayout.walls.find((wall: TwoDMesh) => wall.id === action.payload.wallId);
                if (currentWall) {
                    const currentChild = currentWall.children.find((child: TwoDMesh) => child.id === action.payload.childId);
                    if (currentChild) {
                        if (action.payload.position) {
                            currentChild.position = action.payload.position;
                        }
                        if (action.payload.rotation) {
                            currentChild.rotation = action.payload.rotation;
                        }
                        if (action.payload.width) {
                            currentChild.width = action.payload.width;
                        }
                    }
                }
            });

        },
        //@ts-ignore
        addRoomCornerVertex: (state, action) => {
            return produceWithPatch(state, action, (draft) => {
                const currentWall = draft.twoDRoomLayout.walls.find((wall: TwoDMesh) => wall.id === draft.twoDRoomLayout.activeWallId);
                if (currentWall) {
                    const wallVertices = currentWall.vertices;
                    const targetVertx = getCenterOfTiltedLine(wallVertices[0], wallVertices[1]);
                    const currentIndex = draft.twoDRoomLayout.vertices.findIndex((vertex: TwoDVertex) => vertex.x === wallVertices[0].x && vertex.y === wallVertices[0].y);
                    if (currentIndex > -1) {
                        draft.twoDRoomLayout.vertices.splice(currentIndex + 1, 0, targetVertx);
                    }

                    const wallIndex = draft.twoDRoomLayout.walls.findIndex((wall: TwoDMesh) => wall.id === draft.twoDRoomLayout.activeWallId);
                    const newWallMesh = {
                        id: `${currentWall.id}-1`,
                        type: 'wall',
                        vertices: [targetVertx, currentWall.vertices[1]],
                        position: getCenterOfTiltedLine(targetVertx, currentWall.vertices[1]),
                        rotation: getTwoDMeshRotation(targetVertx, currentWall.vertices[1]),
                        width: getTwoDMeshWidth(targetVertx, currentWall.vertices[1]),
                        children: []
                    }
                    draft.twoDRoomLayout.walls.splice(wallIndex + 1, 0, newWallMesh);
                    currentWall.vertices[1] = targetVertx;
                    currentWall.width = getTwoDMeshWidth(currentWall.vertices[0], currentWall.vertices[1]);
                    currentWall.position = getCenterOfTiltedLine(currentWall.vertices[0], currentWall.vertices[1]);
                }
            });
        },
        updateLayoutBuilderMode: (state, action) => {
            if (action.payload.layoutBuilderMode !== undefined) {
                state.ui.layoutBuilderMode = action.payload.layoutBuilderMode;
                state.twoDRoomLayout.activeWallId = '';
                state.twoDRoomLayout.activeWindowOrDoorId = '';
            }

        },
        updateRoomLabel: (state, action) => {
            if (action.payload.roomLabel !== undefined)
                state.twoDRoomLayout.roomLabel = action.payload.roomLabel;
            if (action.payload.roomType !== undefined)
                state.twoDRoomLayout.roomType = action.payload.roomType;
        },
        updateRoomLayoutSaveStatus: (state, action) => {
            if (action.payload.saveStatus !== undefined)
                state.twoDRoomLayout.saveStatus = action.payload.saveStatus;
        },
        updateRoomLayoutThumbnailCaptureStatus: (state, action) => {
            if (action.payload.captureThumbnailStatus !== undefined)
                state.twoDRoomLayout.captureThumbnailStatus = action.payload.captureThumbnailStatus;
        },
        saveRoomLayoutThumbnailLocally: (state, action) => {
            state.twoDRoomLayout.thumbnailImage = action.payload.image;
            state.twoDRoomLayout.captureThumbnailStatus = 'end';
        },

        updateTwoDUi: (state, action) => {
            if (action.payload.vertices !== undefined)
                state.twoDUi.vertices = action.payload.vertices;
            if (action.payload.boundingRect !== undefined)
                state.twoDUi.boundingRect = action.payload.boundingRect;
            if (action.payload.centerPoint !== undefined)
                state.twoDUi.centerPoint = action.payload.centerPoint;
            if (action.payload.walls !== undefined)
                state.twoDUi.walls = action.payload.walls;
            if (action.payload.showWallCtxMenu !== undefined)
                state.twoDUi.showWallCtxMenu = action.payload.showWallCtxMenu;
            if (action.payload.showSelectWallTooltip !== undefined)
                state.twoDUi.showSelectWallTooltip = action.payload.showSelectWallTooltip
            if (action.payload.showLayoutInfoTooltip !== undefined)
                state.twoDUi.showLayoutInfoTooltip = action.payload.showLayoutInfoTooltip
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRoomModels.fulfilled, (state, { meta, payload, type }) => {
            state.roomModels.models = payload.roomModels;
            state.roomModels.status = BUILDER_SUCCESS;
            const urlParams = state.ui.urlParams;
            //@ts-ignore
            const isEditFlow = urlParams.projectId != null;
            //@ts-ignore
            const targetRoomId = isEditFlow ? state.project.roomModel.roomId : urlParams.roomId;
            const roomModel = state.roomModels.models.find(model => model.roomId === targetRoomId);
            //@ts-ignore
            const isCreateRoomFlow = urlParams.createRoom === 'true';
            if (!roomModel && isEditFlow)
                throw new Error('Room model not available');
            if (roomModel) {
                state.project.roomModel = {
                    roomId: roomModel.roomId,
                    model3DUrl: roomModel.glbUrl,
                    thumbnail: roomModel.thumbnail
                }

                state.ui.isCustomRoomFlow = isCreateRoomFlow || roomModel.roomLayoutType === '2D';

                //@ts-ignore
                if (state.ui.isCustomRoomFlow && (!isCreateRoomFlow || isEditFlow)) {
                    //@ts-ignore
                    state.twoDRoomLayout.vertices = roomModel.data.vertices;
                    //@ts-ignore
                    state.twoDRoomLayout.walls = roomModel.data.walls;
                    //@ts-ignore
                    state.twoDRoomLayout.floorTextureId = roomModel.data.floorTextureId;
                }
                if (!state.ui.isCustomRoomFlow)
                    state.ui.layoutBuilderMode = DimensionalViewType.THREE_D;

                if (isCreateRoomFlow) {
                    state.twoDRoomLayout.roomLabel = roomModel.roomLabel;
                    state.twoDRoomLayout.roomType = roomModel.roomType;
                }
            }

            //@ts-ignore
            if (urlParams.showcase === 'true' && !isEditFlow) {
                let roomModel;
                //@ts-ignore
                if (urlParams.roomType) {
                    const modelsByRoomType = state.roomModels.models.filter(roomModel =>
                        //@ts-ignore
                        roomModel.roomType && roomModel.roomType.toLowerCase() === urlParams.roomType.toLowerCase()
                    );
                    roomModel = modelsByRoomType[0];
                } else {
                    roomModel = state.roomModels.models[0];
                }

                if (roomModel) {
                    state.project.roomModel = {
                        roomId: roomModel.roomId,
                        model3DUrl: roomModel.glbUrl,
                        thumbnail: roomModel.thumbnail
                    }
                    state.ui.isCustomRoomFlow = roomModel.roomLayoutType === '2D';
                    if (state.ui.isCustomRoomFlow && (!isCreateRoomFlow || isEditFlow)) {
                        //@ts-ignore
                        state.twoDRoomLayout.vertices = roomModel.data.vertices;
                        //@ts-ignore
                        state.twoDRoomLayout.walls = roomModel.data.walls;
                        //@ts-ignore
                        state.twoDRoomLayout.floorTextureId = roomModel.data.floorTextureId;
                    }
                }
            }
            if (payload.updateCurrentRoomId) {
                const roomModel = payload.roomModels.find(rm => rm.roomLayoutType !== '2D');
                if (roomModel) {
                    state.activeSelection.roomId = roomModel.roomId;
                    state.project.roomModel = {
                        roomId: roomModel.roomId,
                        model3DUrl: roomModel.glbUrl,
                        thumbnail: roomModel.thumbnail
                    }
                    state.ui.isCustomRoomFlow = false;
                    state.ui.notificationMessage = {
                        message: 'We have found a matching room!!',
                        type: BUILDER_NOTIFICATION_SUCCESS,
                        action: 'replaceRoom',
                        duration: 0
                    }
                } else {
                    state.ui.notificationMessage = {
                        message: 'We could not find a matching room!!',
                        type: BUILDER_NOTIFICATION_ERROR,
                        action: 'replaceRoom',
                        duration: 0
                    }
                }
            }

            if (payload.setDefaultRoom) {
                let roomModel;
                if (payload.roomId != null) {
                    roomModel = payload.roomModels.find(rm => rm.roomId === payload.roomId);
                }
                if (!roomModel)
                    roomModel = payload.roomModels[0];
                if (roomModel) {
                    state.activeSelection.roomId = roomModel.roomId;
                    state.project.roomModel = {
                        roomId: roomModel.roomId,
                        model3DUrl: roomModel.glbUrl,
                        thumbnail: roomModel.thumbnail
                    }
                    state.ui.isCustomRoomFlow = roomModel.roomLayoutType === '2D';
                }
            }
            //@ts-ignore
            if (urlParams.promptDownload === 'true' && !isEditFlow) {
                const roomModel = payload.roomModels.find(rm => rm.roomLayoutType !== '2D');
                if (roomModel) {
                    state.ui.redirectPageUrl = `/builder?roomId=${roomModel.roomId}`;
                }
            }
        })
        builder.addCase(fetchRoomModels.rejected, (state, { meta, payload, error }) => {
            state.roomModels.status = BUILDER_FAILED;
        })
        builder.addCase(fetchRoomLabels.fulfilled, (state, { meta, payload, type }) => {
            const existingRoomLabel = payload.find((obj: { roomLabel: string; }) => obj.roomLabel === state.twoDRoomLayout.roomLabel);
            if (existingRoomLabel != null) {
                state.twoDRoomLayout.roomLabel = `${state.twoDRoomLayout.roomLabel}-${Number(new Date())}`;
            }
        })
        builder.addCase(saveRoomLayout.fulfilled, (state, { meta, payload, type }) => {
            state.roomModels.models.push(payload);
            state.activeSelection.roomId = payload.roomId;
            state.project.roomModel = {
                roomId: payload.roomId,
                model3DUrl: '',
                thumbnail: payload.thumbnail

            };
            state.twoDRoomLayout.saveStatus = TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE;
        })
        builder.addCase(saveRoomLayout.rejected, (state, { meta, payload, error }) => {
            state.twoDRoomLayout.saveStatus = BUILDER_FAILED;
        })
        builder.addCase(saveProject.fulfilled, (state, { meta, payload, type }) => {
            state.project.projectId = payload;
            localStorage.setItem(state.project.projectId!, JSON.stringify(state.project));
            state.ui.isProjectDirty = false;
            state.ui.saveStatus = BUILDER_SUCCESS;
        })
        builder.addCase(saveProject.rejected, (state, { meta, payload, error }) => {
            state.ui.saveStatus = BUILDER_FAILED;
        })
        builder.addCase(loadDesign.fulfilled, (state, { meta, payload, type }) => {
            updateDesignData(state, payload);
        })
        builder.addCase(loadDesign.rejected, (state, { meta, payload, error }) => {
            //state.ui.saveStatus = BUILDER_FAILED;
        })
        builder.addCase(loadBuilderProductsByType.fulfilled, (state, { meta, payload, type }) => {
            addProductsData(state, payload);
        })
        builder.addCase(loadBuilderProductsByType.rejected, (state, { meta, payload, error }) => {
            //state.ui.saveStatus = BUILDER_FAILED;
        })
        builder.addCase(loadDesignStyles.fulfilled, (state, { meta, payload, type }) => {
            state.designContent.designStyles = payload;
            state.ui.designStyleLoadStatus = BUILDER_SUCCESS;
        })
        builder.addCase(loadDesignStyles.rejected, (state, { meta, payload, error }) => {
            state.ui.designStyleLoadStatus = BUILDER_FAILED;
        })


    }
});

const builderReducer = BuilderSlice.reducer;
export const { actions } = BuilderSlice;
export default builderReducer;